import { FC, PropsWithChildren, Suspense } from "react"

import { Button } from "@progress/kendo-react-buttons"
import { Drawer, DrawerContent } from "@progress/kendo-react-layout"
import { useState } from "react"
import { Outlet, To, useLocation, useNavigate } from "react-router-dom"
import { SvgIcon } from "../ui"
import { Avatar } from "@progress/kendo-react-layout";
import "./index.less"

export const items = [
  {
    text: "Capability Map",
    selected: false,
    route: "reports/capability-map",
  },
  {
    text: "Impact Analysis",
    selected: true,
    route: "reports/impact-analysis",
  },
]

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [expanded, setExpanded] = useState(true)

  const handleClick = () => {
    setExpanded(!expanded)
  }

  const onSelect = (e: { itemTarget: { props: { route: To } } }) => {
    navigate(e.itemTarget.props.route);
  };

  return (
    <div className={"default-theme"}>
      <div className='custom-toolbar'>
        <div className={"logo-content"}>
          <Button className={"ghost"} onClick={handleClick}>
            <SvgIcon type={"burger"}/>
          </Button>
          <SvgIcon type={"logo"} className={"logo-main"}/>
        </div>
        <Avatar size={"medium"} />
      </div>

      <div>
        <Drawer
          onSelect={onSelect}
          expanded={expanded}
          position={"start"}
          mode={"push"}
          width={240}
          items={items.map(item => ({
            ...item,
            selected: location.pathname.includes(item.route),
          }))}
          className='drawer'
        >
          <DrawerContent className={"drawer-content"}>
            <Suspense fallback={"loading.."}>
              <Outlet />
              {children}
            </Suspense>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  )
}

export default DefaultLayout
