import { FC, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

import DefaultLayout from '../components/layout';

const Home = lazy<FC>(() => import('./home'));
const ImpactAnalysis = lazy<FC>(() => import('./impact-analysis'));
const CapabilityMap = lazy<FC>(() => import('./capability-map'));
const EditCapabilityMap = lazy<FC>(() => import('./edit-capability-map'));
const EditValueStream = lazy<FC>(() => import('./edit-value-stream'));
const EditValueStage = lazy<FC>(() => import('./edit-value-stage'));

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    children: [
      {
        Component: Home,
        path: '',
        index: true,
      },
      {
        Component: ImpactAnalysis,
        path: 'reports/impact-analysis',
      },
      {
        Component: EditCapabilityMap,
        path: 'reports/edit-capability-map',
      },
      {
        Component: EditValueStream,
        path: 'reports/edit-value-stream',
      },
      {
        Component: EditValueStage,
        path: 'reports/edit-value-stage',
      },
      {
        Component: CapabilityMap,
        path: 'reports/capability-map',
      },
      {
        Component: Outlet,
        path: '*',
      },
    ],
  },
];

export default routes;
