import { FC, SVGProps } from 'react';

import { ReactComponent as AZ } from './images/az.svg';
import { ReactComponent as Filter } from './images/filter.svg';
import { ReactComponent as Menu } from './images/menu.svg';
import { ReactComponent as Settings } from './images/settings.svg';
import { ReactComponent as EditIcon } from './images/edit-icon.svg';
import { ReactComponent as BurgerIcon } from './images/burger.svg';
import { ReactComponent as LogoIcon } from './images/logo.svg';
import { ReactComponent as ShareIcon } from './images/share.svg';
import { ReactComponent as DotsIcon } from './images/dots.svg';
import { ReactComponent as DiskIcon } from './images/disk.svg';
import { ReactComponent as ProductIcon } from './images/product-icon.svg';
import { ReactComponent as CapabilityIcon } from './images/capability-icon.svg';
import { ReactComponent as RunnableIcon } from './images/runnable-icon.svg';
import { ReactComponent as FeatureIcon } from './images/feature-icon.svg';
import { ReactComponent as ArrowIcon } from './images/arrow.svg';
import { ReactComponent as CloseIcon } from './images/close-icon.svg';
import { ReactComponent as DragIcon } from './images/drag-icon.svg';
import { ReactComponent as PlusIcon } from './images/plus.svg';

export type IconTypes =
  | 'edit'
  | 'plus'
  | 'drag'
  | 'close'
  | 'product'
  | 'capability'
  | 'runnable'
  | 'feature'
  | 'search'
  | 'disk'
  | 'burger'
  | 'share'
  | 'dots'
  | 'logo'
  | 'filter'
  | 'menu'
  | 'settings'
  | 'az'
  | 'arrow'
  | string;

const icons: {
  [key: string]: FC<SVGProps<SVGSVGElement>>;
} = {
  edit: EditIcon,
  plus: PlusIcon,
  drag: DragIcon,
  close: CloseIcon,
  burger: BurgerIcon,
  arrow: ArrowIcon,
  runnable: RunnableIcon,
  feature: FeatureIcon,
  product: ProductIcon,
  capability: CapabilityIcon,
  disk: DiskIcon,
  logo: LogoIcon,
  share: ShareIcon,
  dots: DotsIcon,
  filter: Filter,
  menu: Menu,
  settings: Settings,
  az: AZ,
} as const;

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes };

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null;
  return Icon && <Icon {...svgProps} />;
};

export { SvgIcon };
